<template>
  <div>
    <div class="container" id="sponsor-info-cell">
      <div class="pt-4 pb-4">
        <h1 class="text-primary">Sponsors</h1>
      </div>
      <!--
      <b-card class="text-dark bg-primary pt-2 pl-3 pb-0 ml-4 mr-4">
        <p class="text-dark">
          IC Hack is only made possible thanks to the generous support of our
          sponsors.
        </p>
        <h5 class="text-secondary">
          If you are interested in sponsoring IC Hack 23, please contact us at
          <a class="text-secondary" href="mailto:info@ichack.org"
            ><strong>info@ichack.org</strong></a
          >
        </h5>
      </b-card>
    --></div>
    <div class="container">
      <h3 class="text-secondary mt-4">Title Sponsor</h3>

      <div
        class="row justify-content-center mt-4 mb-4"
        style="background: #95e2d6; border-radius: 15px"
      >
        <div class="col-12 d-flex justify-content-center">
          <sponsor-card
            :name="title.name"
            :logo="title.logo"
            :url="title.url"
            :socials="title.socials"
          />
        </div>
      </div>
      <h3 class="text-secondary mt-4">Gold Sponsors</h3>

      <div
        class="row justify-content-center"
        style="background: #ffffff2b; border-radius: 1000px"
      >
        <div
          v-for="sponsor in gold"
          :key="sponsor.name"
          class="col-md-6 justify-content-center d-flex"
        >
          <sponsor-card
            :name="sponsor.name"
            :logo="sponsor.logo"
            :url="sponsor.url"
          />
        </div>
      </div>
      <h3 class="text-secondary mt-4">Silver Sponsors</h3>
      <div class="row justify-content-center">
        <div
          v-for="sponsor in silver"
          :key="sponsor.name"
          class="col-lg-4 col-md-6"
        >
          <sponsor-card
            :name="sponsor.name"
            :logo="sponsor.logo"
            :url="sponsor.url"
          />
        </div>
      </div>
      <h3 class="text-secondary mt-4">Bronze Sponsors</h3>
      <div class="row justify-content-center">
        <div
          v-for="sponsor in bronze"
          :key="sponsor.name"
          class="col-lg-4 col-md-6"
        >
          <sponsor-card
            :name="sponsor.name"
            :logo="sponsor.logo"
            :url="sponsor.url"
          />
        </div>
      </div>
      <h3 class="text-secondary mt-4">Partners</h3>
      <div class="row justify-content-center">
        <div
          v-for="sponsor in partner"
          :key="sponsor.name"
          class="col-lg-4 col-md-6"
        >
          <sponsor-card
            :name="sponsor.name"
            :logo="sponsor.logo"
            :url="sponsor.url"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sponsorData from "../data/sponsors.json";
import SponsorCard from "./SponsorCard.vue";
export default {
  name: "SectionSponsors",
  components: { SponsorCard },
  data: () => {
    return {
      sponsors: sponsorData,
    };
  },
  computed: {
    title() {
      return this.sponsors.title;
    },
    gold() {
      return this.sponsors.gold;
    },
    silver() {
      return this.sponsors.silver;
    },
    bronze() {
      return this.sponsors.bronze;
    },
    partner() {
      return this.sponsors.partner;
    },
  },
};
</script>

<style>
#sponsor-info-cell {
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding-top: 0;
}
</style>
