<template>
  <div class="container" id="gallery">
    <h1 class="text-primary mt-4">Media Gallery</h1>
    <div class="vid-contain mb-4">
      <iframe
        :width="560"
        :height="315"
        src="https://www.youtube.com/embed/i3KXeb08p3E?controls=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="row">
      <div class="col-md-3" v-for="media in medias" :key="media.name">
        <b-card
          class="mb-4 mt-2"
          overlay
          :img-src="media.url"
          img-alt="media.name"
        >
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    medias: [
      {
        name: "Media 1",
        description: "Description 1",
        url: "/gallery/1.jpg",
      },
      {
        name: "Media 2",
        description: "Description 2",
        url: "/gallery/2.jpg",
      },
      {
        name: "Media 3",
        description: "Description 3",
        url: "/gallery/3.jpg",
      },
      {
        name: "Media 4",
        description: "Description 4",
        url: "/gallery/4.jpg",
      },
      {
        name: "Media 5",
        description: "Description 5",
        url: "/gallery/5.jpg",
      },
      {
        name: "Media 6",
        description: "Description 6",
        url: "/gallery/6.jpg",
      },
      {
        name: "Media 7",
        description: "Description 7",
        url: "/gallery/7.jpg",
      },
      {
        name: "Media 8",
        description: "Description 8",
        url: "/gallery/8.jpg",
      },
      {
        name: "Media 9",
        description: "Description 9",
        url: "/gallery/9.jpg",
      },
      {
        name: "Media 10",
        description: "Description 10",
        url: "/gallery/10.jpg",
      },
      {
        name: "Media 11",
        description: "Description 11",
        url: "/gallery/11.jpg",
      },
      {
        name: "Media 12",
        description: "Description 12",
        url: "/gallery/12.jpg",
      },
    ],
  }),
};
</script>

<style>
.vid-contain {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  border-radius: 15px;
  padding-top: 25px;
}
.vid-contain {
  position: relative;
  padding-bottom: 54%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.vid-contain iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
}
.vid-outer {
  padding: 2em;
  border-radius: 30px;
}
</style>
