<template>
  <div class="person-card">
    <img :src="image" :alt="name" class="img-fluid" />
    <div class="person-info">
      <h5 class="person-name">{{ name }}</h5>
      <p>{{ role }}</p>

      <ul class="social-list d-none d-sm-block">
        <li v-for="social in socials" :key="social.url">
          <a :href="social.url"
            ><b-icon class="social-icon" :icon="social.icon"> </b-icon
          ></a>
          <a :href="social.url">{{ social.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonCard",

  props: {
    name: {
      type: String,
      default: "Name",
    },
    role: {
      type: String,
      default: "Role",
    },

    image: {
      type: String,
      default: "https://picsum.photos/300/300",
    },
    socials: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.person-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem;
  padding: 1rem;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 200px;
  background: $navy;
}
.person-name {
  color: $success;
}
.img-fluid {
  width: 150px;
  height: 200px;
  border-radius: 70%;
  margin-right: 1rem;
}
.person-info {
  text-align: left;
  margin-top: 1rem;
}
.social-icon {
  color: $success;
  margin-right: 0.5rem;
}
.social-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  list-style: none;
  padding-left: 0;
}
</style>
