Skip to content Search or jump to… Pull requests Issues Codespaces Marketplace
Explore @sirjacobofglais acowusu / ICHack23Landing Private template Code Issues
Pull requests Actions Projects Wiki Security Insights
ICHack23Landing/src/App.vue @acowusu acowusu The easy bits Latest commit 89d5d92
2 weeks ago History 1 contributor 75 lines (71 sloc) 1.66 KB

<template>
  <div id="app">
    <NavbarFixed />
    <SectionHero />
    <SectionTimeline />
    <SectionFAQ />
    <SectionSponsors />
    <SectionTeam />
    <SectionTimeLine />
    <media-gallery />
    <SectionFooter />
  </div>
</template>

<script>
import NavbarFixed from "./components/NavbarFixed.vue";
import SectionFAQ from "./components/SectionFAQ.vue";
import SectionFooter from "./components/SectionFooter.vue";
import SectionHero from "./components/SectionHero.vue";
import SectionSponsors from "./components/SectionSponsors.vue";
import SectionTeam from "./components/SectionTeam.vue";
import SectionTimeline from "./components/SectionTimeline.vue";
import MediaGallery from "./components/MediaGallery.vue";
import "@luxdamore/vue-cursor-fx/dist/CursorFx.css";
export default {
  name: "App",
  components: {
    NavbarFixed,
    SectionHero,
    SectionFAQ,
    SectionTeam,
    SectionFooter,
    SectionTimeline,
    SectionSponsors,
    MediaGallery,
  },
  data: () => {
    return {
      cursor: {
        color: "#e26c7e",
        hover: "#000",
        config: {
          lerps: {
            dot: 1.5,
            circle: 0.2,
            custom: 0.23,
          },
          scale: {
            ratio: 0.18,
            min: 0.5,
            max: 1,
          },
          opacity: 0.9,
        },
      },
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
#app {
  // background: url(~@/assets/stardust.svg) center space;
  // background-size: 100% auto;
}
</style>
Footer © 2023 GitHub, Inc. Footer navigation Terms Privacy Security Status Docs
Contact GitHub Pricing API Training Blog About ICHack23Landing/App.vue at master
· acowusu/ICHack23Landing
