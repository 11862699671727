<template>
  <div class="container" id="faq">
    <div class="pt-4 pb-4">
      <h1 class="text-primary">Frequently Asked Questions</h1>
    </div>
    <div class="row gx-5">
      <div class="col-lg-6">
        <question-and-answer
          number="1"
          question="What is IC Hack?"
          answer="IC Hack is Imperial College's Annual Hackathon, back bigger than ever for 2023."
          image="/rings/1.svg"
          top="180%"
          left="50%"
        ></question-and-answer>
      </div>
      <div class="col-lg-6">
        <question-and-answer
          number="2"
          question="When / Where is it happening?"
          answer="IC Hack 23 will be held on the 4th and 5th of February 2023, at the Imperial College London South Kensington Campus."
          image="/rings/2.svg"
          top="190%"
          left="50%"
        ></question-and-answer>
      </div>
      <div class="col-lg-6">
        <question-and-answer
          number="3"
          question="How do I get a ticket?"
          answer="Unfortunately, tickets are now sold out for IC Hack 23."
          image="/rings/3.svg"
          top="120%"
          left="45%"
        ></question-and-answer>
      </div>
      <div class="col-lg-6">
        <question-and-answer
          number="4"
          question="How much does it cost to enter?"
          answer="Absolutely nothing!

Thanks to our wonderful sponsors, IC Hack is completely free for participants.

That includes food to keep you going throughout the weekend."
          image="/rings/4.svg"
          top="160%"
          left="50%"
        ></question-and-answer>
      </div>
      <div class="col-lg-6">
        <question-and-answer
          number="5"
          question="How big should the teams be?"
          answer="The maximum number of people allowed in a team is 6."
          image="/rings/5.svg"
          top="130%"
          left="45%"
        ></question-and-answer>
      </div>
      <div class="col-lg-6">
        <question-and-answer
          number="6"
          question="What if I don't have a team?  "
          answer="Don't worry, we'll have a Slack channel for participants to form teams before the event.

You can also form a team when you arrive on the Saturday morning."
          image="/rings/6.svg"
          top="160%"
          left="50%"
        ></question-and-answer>
      </div>
      <div class="col-lg-6">
        <question-and-answer
          number="7"
          question="Who can attend?"
          answer="To be eligible for a ticket, you only have to be a current student from any university, or have graduated within a year!
We encourage anyone from any degree discipline, technical or not, to enjoy IC Hack and show off your skills throughout the weekend.

Unfortunately, due to logistical and legal constraints, we are unable to host under 18s."
          image="/rings/7.svg"
          top="170%"
          left="50%"
        ></question-and-answer>
      </div>
      <div class="col-lg-6">
        <question-and-answer
          number="8"
          question="Do I need loads of experience to participate?"
          answer="Not at all. Whether you are a first year student, study a subject unrelated to computing, or this is your first Hackathon, you are still welcome to enter IC Hack.

This is a great opportunity to learn and gain new experience!"
          image="/rings/8.svg"
          top="140%"
          left="45%"
        ></question-and-answer>
      </div>
      <div class="col-lg-6">
        <question-and-answer
          number="9"
          question="Can I help run IC Hack?"
          answer="Of course, we're always looking for volunteers to help us!

Want to enjoy the weekend without stressing about your project? Or didn't get a ticket but still want to join? Help us out on the day!

Keep an eye on our socials for more information."
          image="/rings/9.svg"
          top="130%"
          left="50%"
        ></question-and-answer>
      </div>
      <div class="col-lg-6">
        <question-and-answer
          number="10"
          question="What is the code of conduct?"
          answer="You can find our code of conduct here: ichack.org/codeofconduct"
          image="/rings/10.svg"
          top="130%"
          left="50%"
        ></question-and-answer>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionAndAnswer from "./QuestionAndAnswer.vue";
export default {
  components: { QuestionAndAnswer },
};
</script>

<style></style>
