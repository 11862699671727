var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"text-primary",attrs:{"id":"schedule"}},[_vm._v("Schedule")]),_c('h4',[_c('span',{class:_vm.isFirst
            ? 'btn btn-lg btn-success   text-dark '
            : 'btn btn-lg btn-dark btn-outline  text-muted',on:{"click":() => {
            _vm.isFirst = true;
          }}},[_vm._v("4th")]),_c('span',{staticClass:"text-muted mx-2"},[_vm._v("|")]),_c('span',{class:!_vm.isFirst
            ? 'btn btn-lg btn-success   text-dark '
            : 'btn btn-lg btn-dark btn-outline  text-muted',on:{"click":() => {
            _vm.isFirst = false;
          }}},[_vm._v("5th")])]),_c('vertical-timeline',{attrs:{"inputData":_vm.currentView,"reversed":_vm.reversed}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }