<template>
  <div>
    <div
      class="outer d-none d-xl-block d-lg-block d-sm-block"
      :style="getImgUrl(image)"
    >
      <!-- <img
      :src="getImgUrl(image)"
      alt="looping burst of color"
      class="faq-image"
    /> -->
      <div class="faq-contents">
        <h2>
          <strong>{{ number }}</strong>
        </h2>
        <h5>{{ question }}</h5>
        <p>{{ answer }}</p>
      </div>
    </div>
    <div class="d-block d-sm-none">
      <h2 class="text-danger text-center">
        <strong>{{ number }}</strong>
      </h2>
      <h5 class="text-success">{{ question }}</h5>
      <p>{{ answer }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: String,
      default: "1",
    },
    question: {
      type: String,
      default: "Question",
    },
    answer: {
      type: String,
      default: "Answer",
    },
    image: {
      type: String,
      default: "/rings/1.svg",
    },
    top: {
      type: String,
      default: "-200%",
    },
    left: {
      type: String,
      default: "-40%",
    },
  },
  computed: {
    transform() {
      return `transform: translate(-${this.left}, -${this.top})`;
    },
  },
  methods: {
    getImgUrl(pic) {
      return `background-image: url(${pic})`;
    },
  },
};
</script>

<style lang="scss">
.outer {
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
  padding: 3em;
}
.faq-contents {
  // position: relative;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -130%);
  text-align: left;
  // max-width: 80%;
  margin: 3em;
  // margin-bottom: -15em;
}
.faq-contents h2 {
  font-size: 3rem;
  text-align: center;
  color: $peach;
}
.faq-contents h5 {
  font-size: 1.5rem;
  text-align: left;
  color: $teal;
  font-weight: 600;
}
.faq-image {
  max-width: 100%;
}
</style>
