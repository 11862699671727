<template>
  <div class="container-fluid">
    <div class="footer row align-items-center justify-content-around">
      <div
        class="col-md-4 d-flex justify-content-center justify-content-md-start justify-content-lg-start justify-content-xl-start"
      >
        <svg
          id="logo-footer"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          width="88.077"
          height="53.441"
          viewBox="0 0 88.077 53.441"
        >
          <g id="Group_38" data-name="Group 38">
            <g
              id="Group_37"
              data-name="Group 37"
              transform="translate(66.124 34.268)"
            >
              <path
                id="Path_77"
                data-name="Path 77"
                d="M521.757,221.384a5.084,5.084,0,0,0-10.167,0h2.68a2.4,2.4,0,1,1,4.193,1.6h0l2,1.787A5.066,5.066,0,0,0,521.757,221.384Z"
                transform="translate(-511.59 -216.3)"
                fill="#002147"
              />
              <path
                id="Path_78"
                data-name="Path 78"
                d="M520.864,269.966l-2-1.786-6.063,6.781h0l-.753.841v1.878h10.108v-2.719H516.4Z"
                transform="translate(-511.991 -261.495)"
                fill="#002147"
              />
            </g>
            <path
              id="Path_79"
              data-name="Path 79"
              d="M425.348,289.215q-2.455-2.919-4.948-5.876c.606-.683,1.2-1.353,1.83-2.049,2.229,2.642,4.446,5.27,6.7,7.925Z"
              transform="translate(-366.09 -238.647)"
              fill="#002147"
            />
            <path
              id="Path_80"
              data-name="Path 80"
              d="M387.3,315.262v-.992a.363.363,0,0,1,.077-.193c.85-.954,1.7-1.894,2.551-2.848.013-.013.039-.013.077-.039v4.072Z"
              transform="translate(-337.275 -264.694)"
              fill="#002147"
            />
            <path
              id="Path_81"
              data-name="Path 81"
              d="M46.313,216.561H0V194.874a2.14,2.14,0,0,1,.039-.284h65.8c-.052.077-.09.155-.142.219-.7.773-1.379,1.546-2.075,2.332-.1.116-.206.232-.322.348H60.011a.563.563,0,0,0-.477.219c-2.178,2.448-4.368,4.884-6.559,7.332-.09.1-.193.206-.283.309-.013-.013-.039-.013-.052-.026v-7.809H49.921V212.18a.767.767,0,0,1-.219.567c-.49.541-.979,1.082-1.456,1.637-.644.722-1.289,1.456-1.933,2.178ZM14.922,197.515H12.2v6.662H5.464v-6.662H2.783v16.159H5.477v-6.739h6.765v6.739h2.693C14.922,208.275,14.922,202.9,14.922,197.515Zm9.768.052c-.026,0-.039-.013-.064-.013-2.139,5.374-4.278,10.734-6.43,16.134h2.912c.18-.438.348-.876.528-1.3h6.057c.18.438.348.876.515,1.289H31.12q-3.209-8.08-6.43-16.108ZM44.7,209.486a5.416,5.416,0,0,1-8.08-7.165,5.182,5.182,0,0,1,3.183-2.01,5.406,5.406,0,0,1,4.923,1.417L46.6,199.86a7.943,7.943,0,0,0-8.2-1.972,8.111,8.111,0,0,0-.077,15.386,8.032,8.032,0,0,0,8.3-1.933c-.631-.619-1.276-1.224-1.92-1.856Z"
              transform="translate(0 -163.119)"
              fill="#002147"
            />
            <path
              id="Path_82"
              data-name="Path 82"
              d="M190.777,0h-26.2c-.374.013-.747.026-1.121.026A.429.429,0,0,0,163.1.18q-9.607,10.786-19.226,21.559c-.052.064-.09.142-.142.219h47.383a2.5,2.5,0,0,0,.026-.309V.052C191,.026,190.893.013,190.777,0ZM171.19,19.084h-4.9V16.378h.838V5.58h-.838V2.9h4.884V5.567h-1.289v10.8h1.289C171.19,17.28,171.19,18.17,171.19,19.084Zm9.239-.271a8.113,8.113,0,0,1-.5-15.489,7.949,7.949,0,0,1,8.35,1.946c-.631.619-1.25,1.237-1.881,1.868a5.412,5.412,0,1,0-.026,7.745c.657.619,1.3,1.224,1.946,1.817a8.016,8.016,0,0,1-7.886,2.113Z"
              transform="translate(-125.117)"
              fill="#002147"
            />
            <path
              id="Path_83"
              data-name="Path 83"
              d="M176.5,279.245c.631-1.611,1.263-3.183,1.881-4.755h.064c.631,1.572,1.263,3.157,1.907,4.755Z"
              transform="translate(-153.702 -232.723)"
              fill="#002147"
            />
            <path
              id="Path_84"
              data-name="Path 84"
              d="M612.205,227.1a3.9,3.9,0,0,0-2.683-1.513l3.142-3.514.753-.841V219.35h-7.679l-2.428,2.716v0h5.759l-2.7,3.019-.753.841h0v1.878h1.916a3.51,3.51,0,0,1,2.646,1.042,2.4,2.4,0,0,1-3.539,3.245l0,0-1.214-1.112-1.581,1.768A5.084,5.084,0,1,0,612.2,227.1Z"
              transform="translate(-525.367 -184.689)"
              fill="#002147"
            />
          </g>
        </svg>
      </div>
      <div class="col-md-4">
        <p class="mt-4">
          IC Hack is organised by DoCSoc, the Imperial College London Department
          of Computing Society.<br />
          Instagram:
          <a class="text-dark" href="https://www.instagram.com/icdocsoc"
            >@ichackuk</a
          >
          <br />
          Twitter:
          <a class="text-dark" href="https://www.twitter.com/icdocsoc"
            >@ICHackUK</a
          >
        </p>
        <p>&copy; 2022 Imperial College DoCSoc.</p>
      </div>
      <div class="col-md-4">
        <a href="http://22.ichack.org">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="232"
            height="36.5"
            viewBox="0 0 232 36.5"
            id="last-year-link"
          >
            <text
              id="Ic_hack_22_website"
              data-name="Ic hack 22 website"
              transform="translate(0 24)"
              fill="#002147"
              font-size="21"
              font-family="SpaceMono-Bold, Space Mono"
              font-weight="700"
            >
              <tspan x="0" y="0">IC HACK 22 WEBSITE</tspan>
            </text>
            <line
              id="Line_1"
              data-name="Line 1"
              x1="230"
              transform="translate(1.5 32.5)"
              fill="none"
              stroke="#002147"
              stroke-width="8"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.footer {
  min-height: 100px;
  padding: 20px;
  color: $navy;

  text-align: center;
  background-color: $success;
  font-size: 12px;
  font-weight: 300;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: space-between;
  .bold-underline {
    color: $navy;
    font-weight: 800;
    text-decoration-thickness: 10px;

    text-decoration: underline;
  }
  #last-year-link {
    max-height: 2em;
  }
}
</style>
