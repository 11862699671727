<template>
  <div class="container" id="home">
    <div class="d-sm-none bg-danger m-4">
      <h1><strong>IC Hack 23</strong></h1>
      <h3>
        <span
          class="highlight type-area-new"
          media="(prefers-reduced-motion: no-preference)"
        ></span>
      </h3>
      <h3>4-5 February 2023 @ Imperial College London</h3>
    </div>

    <div class="row mt-10 flex-column-reverse flex-xl-row">
      <div class="col-xl-6 infos">
        <div class="time">
          <h2>Hacking {{ endStr }} {{ optIn }}:</h2>
          <div style="display: flex">
            <h3 :style="{ width: `${this.llength}ch` }">{{ days }}</h3>
            <h3>Days</h3>
          </div>
          <div style="display: flex">
            <h3 :style="{ width: `${this.llength}ch` }">{{ hours }}</h3>
            <h3>Hours</h3>
          </div>
          <div style="display: flex">
            <h3 :style="{ width: `${this.llength}ch` }">{{ minutes }}</h3>
            <h3>Minutes</h3>
          </div>
          <div style="display: flex">
            <h3 :style="{ width: `${this.llength}ch` }">{{ seconds }}</h3>
            <h3>Seconds</h3>
          </div>
          {{ optAgo }}
        </div>
        <div>
          <b-button
            pill
            lg="4"
            variant="outline-danger"
            class="hero-btn"
            :disabled="true"
            ><h1>Tickets sold out!</h1></b-button
          >
        </div>
      </div>

      <div class="col-xl-6 splodge md-order-first d-sm-block d-none">
        <img
          src="../assets/splodge.svg"
          alt="Burst of color"
          class="splodge-image"
        />
        <div class="splodge-contents">
          <h1><strong>IC Hack 23</strong></h1>
          <h3>
            <span class="highlight type-area"></span>
          </h3>
          <h3>4-5 February 2023 @ Imperial College London</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Typed from "typed.js";

export default {
  name: "SectionHero",
  data() {
    return {
      start: new Date("2023-02-04T12:00:00"),
      end: new Date("2023-02-05T12:00:00"),
      altText: false,
    };
  },

  computed: {
    optIn() {
      return this.end >= new Date() ? " in" : "";
    },
    optAgo() {
      return this.end < new Date() ? "<h3>ago</h3>" : "";
    },
    endStr() {
      return this.end < new Date()
        ? "ended"
        : this.start < new Date()
        ? "ends"
        : "begins";
    },
    llength() {
      let ll = Math.max(`${this.days}`.length, 2) + 1;
      console.log(ll);
      return ll;
    },
    days() {
      let date = this.start <= new Date() ? this.end : this.start;
      return Math.abs(Math.floor((date - new Date()) / 1000 / 60 / 60 / 24));
    },
    hours() {
      let date = this.start <= new Date() ? this.end : this.start;
      return Math.abs(Math.floor((date - new Date()) / 1000 / 60 / 60) % 24);
    },
    minutes() {
      let date = this.start <= new Date() ? this.end : this.start;
      return Math.abs(Math.floor((date - new Date()) / 1000 / 60) % 60);
    },
    seconds() {
      let date = this.start <= new Date() ? this.end : this.start;
      return Math.abs(Math.floor((date - new Date()) / 1000) % 60);
    },
    display() {
      return `${this.hours}h ${this.minutes}m ${this.seconds}s`;
    },
  },
  created() {
    setInterval(this.update, 1000);
  },
  mounted() {
    const options = {
      strings: ["The UK's largest student-run hackathon", "Powered by Cisco"],
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
      showCursor: false,
    };
    new Typed(".type-area", options);
    new Typed(".type-area-new", options);
  },
  methods: {
    update() {
      this.start = new Date("2023-02-04T10:00:00");
    },
  },
};
</script>

<style lang="scss">
.splodge-contents {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -40%);
  text-align: left;
}
.splodge-image {
  max-width: 100%;
}
.highlight {
  background-color: $navy;
  color: $peach;
  padding: 0 0.5rem;
  @media (prefers-reduced-motion) {
    display: none;
  }
}
.splodge {
  color: $navy;
}
.time {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // align-items: center;
  margin-bottom: 5rem;
  color: $purple;
}
.infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hero-btn {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-width: large;
}
.hero-btn > h1 > a:hover {
  text-decoration: none;
}
</style>
