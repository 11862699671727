import Vue from "vue";

// import BootstrapVue from "bootstrap-vue";
import {
  BIcon,
  BIconAt,
  BIconBatteryFull,
  BIconChatDotsFill,
  BIconEnvelope,
  BIconGit,
  BIconGithub,
  BIconGlobe,
  BIconLinkedin,
  BIconMessenger,
  BIconMeta,
  BIconReddit,
  BIconSnapchat,
  BootstrapVue,
} from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.component("BIcon", BIcon);
Vue.component("BIconAt", BIconAt);
Vue.component("BIconBatteryFull", BIconBatteryFull);
Vue.component("BIconChatDotsFill", BIconChatDotsFill);
Vue.component("BIconEnvelope", BIconEnvelope);
Vue.component("BIconGit", BIconGit);
Vue.component("BIconGithub", BIconGithub);
Vue.component("BIconGlobe", BIconGlobe);
Vue.component("BIconLinkedin", BIconLinkedin);
Vue.component("BIconMessenger", BIconMessenger);
Vue.component("BIconMeta", BIconMeta);
Vue.component("BIconReddit", BIconReddit);
Vue.component("BIconSnapchat", BIconSnapchat);
