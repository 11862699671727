<template>
  <div>
    <!-- <div class="custom-shape-divider top">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
          class="shape-fill"
        ></path>
      </svg>
    </div> -->
    <div class="team-content">
      <div class="container">
        <h1 class="text-primary mt-4">Team</h1>
        <!-- <h3><span class="highlight">Meet the Team</span></h3> -->
        <carousel :perPage="1">
          <slide v-for="(group, i) in teamChunks" :key="i">
            <div class="row">
              <div v-for="person in group" :key="person.name" class="col-lg-6">
                <person-card
                  :name="person.name"
                  :role="person.role"
                  :image="person.image"
                  :socials="person.socials"
                ></person-card>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
    <!-- <div class="custom-shape-divider bottom">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
          class="shape-fill"
        ></path>
      </svg>
    </div> -->
  </div>
</template>

<script>
import Team from "../data/team.json";
import PersonCard from "./PersonCard.vue";
import Carousel from "../../node_modules/vue-carousel/src/Carousel.vue";
import Slide from "../../node_modules/vue-carousel/src/Slide.vue";

const chunks = (a, size) =>
  Array.from(new Array(Math.ceil(a.length / size)), (_, i) =>
    a.slice(i * size, i * size + size)
  );

export default {
  components: { PersonCard, Carousel, Slide },
  data() {
    return {
      team: Team,
    };
  },
  computed: {
    teamChunks() {
      return chunks(this.team, 4);
    },
  },
};
</script>

<style lang="scss">
.carousel {
  width: 100%;
  overflow: hidden;
}

.slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-shape-divider {
  /* position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; */
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider.top {
  transform: rotate(180deg) translateY(-1px);
}
.custom-shape-divider.bottom {
  transform: rotate(180deg) translateY(10px);
}
.custom-shape-divider svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 175px;
  transform: rotateY(180deg);
}

.custom-shape-divider .shape-fill {
  fill: $purple;
}
</style>
