<template>
  <div>
    <b-navbar toggleable="lg" type="dark" class="navbar">
      <b-navbar-brand href="#">
        <img src="../assets/logo.svg" alt="IC Hack 23" />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item right href="#schedule">Schedule</b-nav-item>
          <b-nav-item right href="#faq">FAQ</b-nav-item>
          <b-nav-item right href="#sponsor-info-cell">Sponsors</b-nav-item>
          <b-nav-item right href="#gallery">Gallery</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {};
</script>

<style>
.navbar {
  background-color: #041426b6;
}
</style>
